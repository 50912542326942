<template>
  <div>
    <Loading :isActive="!contentLoaded && !loadingErrorMessage" message="Validando sessão ..." />
    <div class="c-app" v-if="contentLoaded">
      <TheSidebar
        :show="show"
        :toggleShowHide="toggleShowHide"
        :modulos="carga.modulos"
        :sistemas="sistemas"
        :loadSistemas="loadSistemas"
      />
      <CWrapper>
        <TheHeader :toggleShowHide="toggleShowHide" />
        <div class="c-body">
          <main class="c-main">
            <CContainer fluid>
              <transition name="fade" mode="out-in">
                <router-view
                  :carga="carga"
                  :sistemas="sistemas"
                  :loadSistemas="loadSistemas"
                  :key="$route.path"
                />
              </transition>
            </CContainer>
          </main>
        </div>
        <TheFooter/>
      </CWrapper>
    </div>
    <div v-if="loadingErrorMessage">
      <CAlert show color="danger" class="text-center">
        {{ loadingErrorMessage }}
      </CAlert>
    </div>
  </div>
</template>

<script>
  import TheSidebar from './TheSidebar'
  import TheHeader from './TheHeader'
  import TheFooter from './TheFooter'
  import { get } from '../../helpers/apiRequest';
  import { loadCargas } from "../../helpers/cargas";
  import config from '../../config';

  export default {
    name: 'TheContainer',
    components: {
      TheSidebar,
      TheHeader,
      TheFooter
    },
    data() {
      return {
        show: true,
        sistemas: null,
        cargaValidada: false,
        carga: {},
        loadingErrorMessage: null
      }
    },
    computed: {
      contentLoaded() {
        return this.sistemas !== null && this.cargaValidada;
      },
    },
    methods: {
      toggleShowHide() {
        this.show = !this.show;
      },
      loadSistemas() {
        get('/admin/sistemas')
          .then((json) => {
            let _sistemas = [];

            json.map((sistema) => {
              _sistemas[sistema.chave] = sistema
            });

            this.sistemas = {..._sistemas};
            this.loadingErrorMessage = null;
          })
          .catch((err) => {
            this.invalidarCarga();
            this.loadingErrorMessage = `Link de API não respondendo (${config.api}), tente atualizar a página, caso o problema persista, contate o suporte técnico.`;
            this.sistemas = null;
          });
      },
      checkReloadCarga() {
        if (!!localStorage.getItem('revalidar_carga')) {
          loadCargas(
            config.token(),
            (carga) => {
              this.carga = carga;
              localStorage.setItem('revalidar_carga', 0);
              this.cargaValidada = true;
            }
          );
          return;
        }

        this.cargaValidada = true;
      },
      initCarga() {
        const app = localStorage.getItem("app");
        this.carga = !!app ? JSON.parse(localStorage.getItem("app")) : {};
      },
      initWebSocket() {
        if (this.$pusher) {
          const channelGeral = this.$pusher.subscribe('geral');

          channelGeral.bind('parametro-atualizado', this.invalidarCarga);
          channelGeral.bind('perfil-atualizado', this.invalidarCarga);
        }
      },
      invalidarCarga() {
        localStorage.setItem('revalidar_carga', 1);
        this.checkReloadCarga();
      }
    },
    beforeMount() {
      this.loadSistemas();
      this.checkReloadCarga();
      this.initWebSocket();
      this.initCarga();
    }
  }
</script>

<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
</style>