const menus = [
  // Para buscar ícones, consultar em : https://icons.coreui.io/icons/
  {
    menu: 'Cadastros',
    href: '/cadastros',
    children: [
      {
        modulo: "ADMIN_DOMINIO",
        href: "/cadastros/api-gateway",
        icon: "cil-vector",
      },
      {
        modulo: "ADMIN_COLABORADOR",
        href: "/cadastros/colaboradores",
        icon: "cil-user",
      },
      {
        modulo: "ADMIN_CLIENTE",
        href: "/cadastros/clientes",
        icon: "cil-group",
      },
      {
        modulo: "ADMIN_EXPEDIENTE",
        href: "/expediente/expediente",
        icon: "cil-calendar-check",
      },
      {
        modulo: "ADMIN_FAQ",
        href: "/cadastros/faq",
        icon: "cil-notes",
      },
      {
        modulo: "ADMIN_MODULO",
        href: "/cadastros/modulos",
        icon: "cil-sitemap",
      },
      {
        modulo: "ADMIN_PERFIL",
        href: "/cadastros/perfis",
        icon: "cil-address-book",
      },
      {
        modulo: "ADMIN_PRODUTO",
        href: "/cadastros/produtos",
        icon: "cil-basket",
      },
   /*    {
        modulo: "ADMIN_PRECIFICACAO_CLIENTE_FINAL",
        href: "/cadastros/precificacao-cliente-final",
        icon: "cil-calculator",
      }, */
      {
        modulo: "ADMIN_SAC",
        href: "/cadastros/sac",
        icon: "cil-comment-bubble",
      },
      {
        modulo: "ADMIN_SETOR_ATENDIMENTO",
        href: "/cadastros/setores",
        icon: "cil-room",
      },
    ]
  },
  {
    menu: 'Campanhas',
    href: '/campanhas',
    children: [
      {
        modulo: "ADMIN_CAMPANHA",
        href: "/campanhas/campanhas",
        icon: "cil-briefcase",
      },
    ]
  },
  {
    menu: 'Marketing',
    children: [
      {
        modulo: "ADMIN_MARKETING",
        href: "/marketing/marketing",
        icon: "cil-bullhorn",
      },
      {
        modulo: "ADMIN_CANAL_ATENDIMENTO",
        href: "/marketing/canais-atendimento",
        icon: "cil-chat-bubble",
      },
      {
        modulo: "ADMIN_CATALOGO",
        href: "/marketing/catalogos",
        icon: "cil-book",
      },
      {
        modulo: "ADMIN_CUPOM",
        href: "/marketing/cupons",
        icon: "cil-tag",
      },
      {
        modulo: "ADMIN_DEPARTAMENTO",
        href: "/marketing/departamentos",
        icon: "cil-list-rich",
      },
      {
        modulo: "ADMIN_FILIAL",
        href: "/marketing/filiais",
        icon: "cil-building",
      },
      {
        modulo: "ADMIN_LINK",
        href: "/marketing/links",
        icon: "cil-link",
      },
      {
        modulo: "ADMIN_LOGO",
        href: "/marketing/logos",
        icon: "cil-image-plus",
      },
      {
        modulo: "ADMIN_MARCA",
        href: "/marketing/marcas",
        icon: "cil-badge",
      },
      {
        modulo: "ADMIN_MERSHANDISING",
        href: "/marketing/mershandising",
        icon: "cil-money",
      },
      {
        modulo: "ADMIN_SOCIAL",
        href: "/marketing/sociais",
        icon: "cil-thumb-up",
      },
      {
        modulo: "ADMIN_PAGINA",
        href: "/marketing/paginas",
        icon: "cil-library",
      },
      {
        modulo: "ADMIN_FORMULARIOS",
        href: "/marketing/formularios",
        icon: "cil-list", 
      },
      {
        modulo: "ADMIN_POSTAGEM",
        href: "/marketing/postagens",
        icon: "cil-newspaper",
      },
      {
        modulo: "ADMIN_PAGINA_TERMOS",
        href: "/marketing/pagina-termos",
        icon: "cil-comment-square",
      },
      {
        modulo: "ADMIN_TRABALHE_CONOSCO",
        href: "/marketing/trabalhe-conosco",
        icon: "cil-briefcase",
      },
      {
        modulo: "ADMIN_SECAO_RODAPE",
        href: "/marketing/secoes-rodape",
        icon: "cil-list-low-priority",
      },
    ]
  },
  {
    menu: 'Regra de Negócio',
    href: '/regra-negocio',
    children: [
      {
        modulo: "ADMIN_REGRA_NEGOCIO",
        href: "/regra-negocio/regra",
        icon: "cil-money",
      },
    ],
  },
  {
    menu: 'Entrega',
    href: '/entrega',
    children: [
      {
        modulo: "ADMIN_ENTREGA",
        href: "/entrega/condicao-entrega",
        icon: "cil-truck",
      },
    ],
  },
  {
    menu: 'Pagamento',
    href: '/pagamento',
    children: [
      {
        modulo: "ADMIN_PAGAMENTO",
        href: "/pagamento/pagamento",
        icon: "cil-money",
      },
      {
        modulo: "ADMIN_CONDICAO_PAGAMENTO",
        href: "/pagamento/condicao-pagamento",
        icon: "cil-money",
      },
    ],
  },
  {
    menu: 'Pedidos',
    href: '/pedidos',
    children: [
      {
        modulo: "ADMIN_PEDIDO_INTEGRACAO",
        href: "/pedidos/pedidos-pendentes-integracao",
        icon: "cil-sofa",
      },
      {
        modulo: "ADMIN_PEDIDO_TIMELINE",
        href: "/pedidos/pedidos-timeline",
        icon: "cil-playlist-add",
      },
    ]
  },
  {
    menu: 'Notificações',
    href: '/notificacoes',
    children: [
      {
        modulo: "ADMIN_NOTIFICACAO",
        href: "/notificacoes/notificacoes",
        icon: "cil-bullhorn",
      },
    ]
  },
  {
    menu: 'Configurações',
    href: '/configuracoes',
    children: [
      {
        modulo: "ADMIN_CONFIGURACAO",
        href: "/configuracoes/configuracoes",
        icon: "cil-cog",
      },
      {
        modulo: "ADMIN_PARAMETRO",
        href: "/configuracoes/parametros",
        icon: "cil-list-numbered",
      },
      {
        modulo: "ADMIN_DASHBOARD",
        href: "/configuracoes/dashboards",
        icon: "cil-bar-chart",
      },
      {
        modulo: "ADMIN_RELATORIO",
        href: "/configuracoes/relatorios",
        icon: "cil-chart-line",
      },
      {
        modulo: "ADMIN_ACOMPANHAMENTO_CLIENTE",
        href: "/configuracoes/funis-acompanhamento",
        icon: "cil-columns",
      },
      {
        modulo: "ADMIN_VIEW_TEMPLATE",
        href: "/configuracoes/views-templates",
        icon: "cil-file",
      },
      {
        modulo: "ADMIN_CAMPOS_INTEGRACAO",
        href: "/configuracoes/campos-integracao",
        icon: "cil-swap-horizontal",
      },
      {
        modulo: "ADMIN_SQL_ERP_INTEGRACAO",
        href: "/configuracoes/sql-erp-integracao",
        icon: "cil-code",
      },
      {
        modulo: "ADMIN_CARGA_DADOS",
        href: "/configuracoes/cargas",
        icon: "cil-cloud-download",
      },
      {
        modulo: "ADMIN_LOG",
        href: "/configuracoes/logs",
        icon: "cil-history",
      },
    ]
  },
];

export default menus;
