<template>
  <!--<nav class="sidebar">-->
    <!--<ul class="nav">-->
      <!--<li class="nav-item nav-app">-->
        <!--<h3>-->
          <!--ADMIN-->
          <!--<small>v1.1</small>-->
        <!--</h3>-->
      <!--</li>-->
      <!--<li-->
        <!--v-bind:key="item.modulo"-->
        <!--v-bind:class="{ 'active': item.sel.indexOf(currentUrl) > -1 }"-->
        <!--v-for="item in menus"-->
      <!--&gt;-->
        <!--<router-link :to="item.href" class="nav-link">-->
          <!--<i>-->
            <!--<fa-icon :icon="item.icon"/>-->
          <!--</i>-->
          <!--<span>{{ item.descPlural }}</span>-->
        <!--</router-link>-->
      <!--</li>-->
    <!--</ul>-->
  <!--</nav>-->
  <CSidebar dropdownMode="openActive" colorScheme="light" :show="show" @update:show="toggleShowHide">
    <CSidebarBrand class="d-md-down-none bg-white c-header">
      <img v-bind:src="logo" alt="logo" class="menu_logo">
    </CSidebarBrand>

    <!--<CRenderFunction flat :content-to-render="menusAutorizados"/>-->
    <!--<CSidebarNav>-->
      <!--<CSidebarNavItem name="Home" />-->
    <!--</CSidebarNav>-->
    <CSidebarNav sync>
      <CSidebarNavItem name="Home" to="/" />

      <CSidebarNavDropdown
        v-for="menu in menus"
        :key="menu.menu"
        :name="menu.menu"
        :route="menu.href"
      >
          <CSidebarNavItem
            v-for="itemMenu in menu.children"
            :key="itemMenu.href"
            :name="itemMenu.menu"
            :to="itemMenu.href"
            :icon="itemMenu.icon"
          />
      </CSidebarNavDropdown>
    </CSidebarNav>
  </CSidebar>
</template>
<script>
import config from '../../config';
import sidebarMenus from '../../config/sideBar';
import _map from 'lodash/map';
import _find from 'lodash/find';
import clone from "just-clone";

export default {
  name: "TheSidebar",
  props: [
    'show',
    'toggleShowHide',
    'modulos'
  ],
  computed: {
    menus() {

      const menus = [];

      _map(this.availableMenus, (availableMenu) => {

        let subMenu = [];

        _map(availableMenu.children, (itemMenu) => {

          let modulo = _find(this.modulos, { 'nome' : itemMenu.modulo } );

          if (modulo && modulo.autorizado) {
            let item = clone(itemMenu);
            item.menu = modulo.descPlural;
            subMenu.push(item);
          }

        });

        if (subMenu.length > 0) {
          let menu = clone(availableMenu);
          menu.children = subMenu;
          menus.push(menu);
        }

      });

      return menus;
    },
  },
  data() {
    return {
      currentUrl: "",
      logo: config.api+'/images/logo',
      availableMenus: sidebarMenus
    };
  },
  beforeMount() {
    this.currentUrl = this.$route.name;
  },
  watch: {
    $route(to, from) {
      this.currentUrl = to.name;
    },
  },
};
</script>
